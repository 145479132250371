<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มคลังสินค้า
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="required form-label col-sm-10"
            >รหัสคลัง</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.warehouse_code"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="required form-label col-sm-10"
            >ชื่อคลัง</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.warehouse_name"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >กำหนดเป็นคลังหลัก</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.selectMainWarehouse"
            >
              <option value="1">ใช่</option>
              <option value="2">ไม่ใช่</option>
            </select>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  setup() {
    document.title = "BELUCA | เพิ่มคลังสินค้า";
  },
  data() {
    return {
      loading: false,
      isSubmit: false,
      form: {
        warehouse_code: "",
        warehouse_name: "",
        selectMainWarehouse: "1",
        status: "0",
      },
      ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    };
  },
  methods: {
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          warehouse_main: this.form.selectMainWarehouse === "1" ? true : false,
          companyId: parseInt(localStorage.getItem("companyId")),
          companyBranchId: parseInt(localStorage.getItem("companyBranchId")),
        };

        createResponse = await whApi.warehouse.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/WH1",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "เพิ่มไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      !this.form.warehouse_code
        ? (this.isValid = false)
        : !this.form.warehouse_name
        ? (this.isValid = false)
        : !this.form.selectMainWarehouse
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    cancel() {
      this.$router.push({
        path: "/warehouse/WH1",
        query: {},
      });
    },
  },
};
</script>
